import { render, staticRenderFns } from "./InputDialog.vue?vue&type=template&id=0579f30c"
import script from "./InputDialog.vue?vue&type=script&lang=ts"
export * from "./InputDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_5d32d4a8/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0579f30c')) {
      api.createRecord('0579f30c', component.options)
    } else {
      api.reload('0579f30c', component.options)
    }
    module.hot.accept("./InputDialog.vue?vue&type=template&id=0579f30c", function () {
      api.rerender('0579f30c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/generic/InputDialog.vue"
export default component.exports