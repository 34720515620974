import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=54c38a05"
import script from "./Admin.vue?vue&type=script&lang=ts"
export * from "./Admin.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_5d32d4a8/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54c38a05')) {
      api.createRecord('54c38a05', component.options)
    } else {
      api.reload('54c38a05', component.options)
    }
    module.hot.accept("./Admin.vue?vue&type=template&id=54c38a05", function () {
      api.rerender('54c38a05', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Admin.vue"
export default component.exports