import { render, staticRenderFns } from "./UserModal.vue?vue&type=template&id=306ed1b4&scoped=true"
import script from "./UserModal.vue?vue&type=script&lang=ts"
export * from "./UserModal.vue?vue&type=script&lang=ts"
import style0 from "./UserModal.vue?vue&type=style&index=0&id=306ed1b4&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306ed1b4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_5d32d4a8/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('306ed1b4')) {
      api.createRecord('306ed1b4', component.options)
    } else {
      api.reload('306ed1b4', component.options)
    }
    module.hot.accept("./UserModal.vue?vue&type=template&id=306ed1b4&scoped=true", function () {
      api.rerender('306ed1b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/global/UserModal.vue"
export default component.exports